import { Button, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useCommonUI } from '../context/UI';
import { useApiRequest } from '../store/Common';
import moment from 'moment';
import { toast } from 'react-toastify';

function PickupPage() {

    const {postData} = useApiRequest();
    const {openSnackbar} = useCommonUI();

    const [inputPhone, setInputPhone] = useState('');
    const [customerName, setCustomerName] = useState('');

    const [isScheduling, setIsScheduling] = useState(false);
    const [selectedDate, setSelectedDate] = useState({id:-1});
    const [selectedTime, setSelectedTime] = useState({id:-2});
    const [dateList, setDateList] = useState([]);
    const [timeList, setTimeList] = useState([]);


    useEffect(()=>{
        const getDayInfo = (offset) => {
            const date = moment().add(offset, 'days');
            let name = date.format('dddd');
            if(offset === 0){
                name = "Today"
            }
            if(offset === 1){
                name = "Tomorrow"
            }
            return {
                id: offset.toString(),
                name: name.toUpperCase(), 
                date: date.format('DD MMM'),
                year: date.format('Y')
            };
        };
        const generateData = () => {
            const data = [];
            const currentHour = moment().hour();
            for (let i = 0; i <= 7; i++) {
                if (i===0 && 19 < currentHour){ 
                    continue;
                }
                if (i===7 && 20 > currentHour){ 
                    continue;
                }
                data.push(getDayInfo(i));
            }
            setDateList(data);
        };
        generateData();
    },[])

    useEffect(()=>{
        const generateTimeData = () => {
            const data = [];
            const currentHour = moment().hour();
        
            for (let i = 8; i <= 20; i++) {
                if (selectedDate.id === '0' && i <= currentHour){ 
                    continue;
                }
                const time = moment().startOf('day').add(i, 'hours');
                const time2 = moment().startOf('day').add(i+1, 'hours');
                data.push({
                    id: i.toString(),
                    time: time.format('h A')+" - "+time2.format('h A'),
                });
            }
            setTimeList(data);
        };
        generateTimeData();
    },[selectedDate]);

    const datePress = (item) =>{
        setSelectedDate(item);
        setSelectedTime({id: -2});
    }
    const timePress =  (item) => {
        if(selectedDate.id !== -1){
            setSelectedTime(item)
        }
    }

    const scheduleOrder = async() =>{
        if(isScheduling){
            return;
        }
        const regex = /^\d{10}$/;
        if(!regex.test(inputPhone)){
            openSnackbar('Phone Number Incorrect', 'error');
            return;
        }
        if(selectedDate.id === -1 || selectedTime.id === -2 || !inputPhone || !customerName){
            openSnackbar('Missing Something', "error");
            return;
        }
        setIsScheduling(true);
        const tid = toast.loading('Scheduling Order',{position:'bottom-right'});
        const dateObject = moment(`${selectedDate.date} ${selectedDate.year} ${selectedTime.time}`, "DD MMM YYYY h A");
        const epochTime = dateObject.unix()*1000;
        let postdata = {
            phone: inputPhone,
            date: epochTime,
            name: customerName
        };
        const resp = await postData('order/pickup', postdata);
        if(resp){
            setSelectedDate({id:-1})
            setSelectedTime({id:-2})
            setInputPhone('');
            setCustomerName('');
            toast.update(tid, { render: "Order Scheduled", type: "success", isLoading: false, autoClose: 2000 });
        }else{
            toast.dismiss();
        }
        setIsScheduling(false);
    }

    return (
        <Stack sx={{width: '100%', height: '100%', padding: 2, backgroundColor: '#f4f4f4'}}>
            <Stack sx={{flexDirection: 'row', gap: 2}}>
                <Stack sx={{background: '#fff', padding: 2, borderRadius: 5, flex: 1, flexDirection: 'row', gap: 5}}>
                    <TextField fullWidth variant='outlined' label="Phone Number" type='number' value={inputPhone} onChange={(e)=>{setInputPhone(e.target.value)}} />
                    <TextField fullWidth variant='outlined' label='Customer Name' value={customerName} onChange={(e)=>{setCustomerName(e.target.value)}} />
                </Stack>
            </Stack>

            <Stack my={2} p={2} sx={{background: '#fff', borderRadius: 5}}>
                <Typography variant='h5' fontWeight={'bold'}>Schedule Time</Typography>
                <Typography fontWeight={600} mt={2} mb={1}>Select Date</Typography>
                <Stack sx={{flexDirection: 'row', gap: 4}}>
                    {dateList.map(item=>{
                        const isSelected = item.id === selectedDate.id;
                        return <Stack onClick={()=>{datePress(item)}} key={item.id} sx={{backgroundColor: isSelected?'#d9166f':'#eee', width: '150px', borderRadius: 5, padding: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', color: isSelected?'#fff':'#000'}}>
                            <Typography>{item.name}</Typography>
                            <Typography variant='h6' fontWeight={600}>{item.date}</Typography>
                        </Stack>
                    })}
                </Stack>
                <Typography fontWeight={600} mt={2} mb={1}>Select Time</Typography>
                <Stack sx={{flexDirection: 'row', gap: 2, flexWrap: 'wrap'}}>
                    {timeList.map(item=>{
                        const isSelected = item.id === selectedTime.id;
                        return <Stack onClick={()=>{timePress(item)}} key={item.id} sx={{backgroundColor: isSelected?'#d9166f':'#eee', width: '170px', borderRadius: 5, padding: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', color: isSelected?'#fff':'#000'}}>
                            <Typography fontWeight={600}>{item.time}</Typography>
                        </Stack>
                    })}
                </Stack>
            </Stack>
            <Stack sx={{alignItems:'center'}} mt={2}>
                <Button variant='contained' sx={{width: 500}} onClick={scheduleOrder}>Schedule Order</Button>
            </Stack>

        </Stack>
    )
}

export default PickupPage