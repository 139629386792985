import { Dashboard, Group, Logout, Schedule, ViewList, VpnKey } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useCommonUI } from '../context/UI';
import { useApiRequest } from '../store/Common';
import { updateLogin } from '../store/slices/configSlice';

function Navbar({partnerName, type}) {

    const location = useLocation();
    const dispatch = useDispatch();
    const {fetchData} = useApiRequest();
    const {openSnackbar, openDialog, openSessions} = useCommonUI();
    const isActive = (path) => location.pathname === path?'mactive':'';
    const [isLogout, setIsLogout] = useState(false);

    const logout = () =>{
        if(isLogout){
            openSnackbar('Logout in progress', 'error');
        }
        openDialog("Logout?", "Are you sure you want to Logout?", confirmLogout, null);
    }
    const confirmLogout = async() =>{
        openSnackbar('Logging you out', 'info');
        setIsLogout(true);
        const resp = await fetchData('app/logout');
        if(resp){
            dispatch(updateLogin(false));
            localStorage.removeItem('session');
            openSnackbar("Logged Out", 'success');
        }
        setIsLogout(false);
    }

    return (
        <Box className='navbar'>
            <Stack sx={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingX: 3}}>

                <Stack flexDirection='row' gap={1} alignItems='center' paddingY='10px'>
                    <img src='/assets/dryfi.png' alt='Dryfi' height='70px'/>
                </Stack>

                <Stack sx={{flexDirection: 'row', gap: 2}}>
                    <Link className={'menu '+isActive('/')} to='/'>
                        <Dashboard fontSize='10'/>
                        <Typography fontWeight='bold'>Dashboard</Typography>
                    </Link>
                    {type === "hotel" && <Link className={'menu '+isActive('/pickup')} to='/pickup'>
                        <Schedule fontSize='10'/>
                        <Typography fontWeight='bold'>Schedule</Typography>
                    </Link>}
                    <Link className={'menu '+isActive('/orders')} to='/orders'>
                        <ViewList fontSize='10'/>
                        <Typography fontWeight='bold'>Orders</Typography>
                    </Link>
                    {type === "society" && <Link className={'menu '+isActive('/customers')} to='/customers'>
                        <Group fontSize='10'/>
                        <Typography fontWeight='bold'>Customers</Typography>
                    </Link>}
                    <Stack className='menu' sx={{cursor: 'pointer'}} flexDirection={'row'} alignItems={'center'} gap={0.5} onClick={openSessions}>
                        <VpnKey fontSize='10'/>
                        <Typography fontWeight='bold'>Sessions</Typography>
                    </Stack>
                    <Stack className='menu' sx={{cursor: 'pointer'}} flexDirection={'row'} alignItems={'center'} gap={0.5} onClick={logout}>
                        <Logout fontSize='10'/>
                        <Typography fontWeight='bold'>Logout</Typography>
                    </Stack>
                </Stack>

                <Typography variant='h5'><b>{partnerName}</b></Typography>
            </Stack>
        </Box>
    )
}

const mapStateToProps = state => ({
    partnerName: state.configs.partnerName,
    type: state.configs.type
});
export default connect(mapStateToProps)(Navbar)