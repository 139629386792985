import { Close, SearchTwoTone } from '@mui/icons-material'
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { DateRangePicker } from 'rsuite'
import { currency, useApiRequest } from '../store/Common';
import { ClipLoader } from 'react-spinners';
import { connect } from 'react-redux';

function Dashboard({type, share}) {

    const { postData} = useApiRequest();
    const {afterToday} = DateRangePicker;
    const [isLoading, setIsLoading] = useState(false);
    const [orderStat, setOrderStat] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [nonGstValue, setNonGstValue] = useState(0);
    const [shareValue, setShareValue] = useState(0);

    const getOrderData = useCallback(async(body) =>{
        setIsLoading(true);
        setOrderStat(null);
        const resp = await postData('app/stat', body);
        if(resp){
            setOrderStat(resp.data);
            let nongst = resp.data.totalOrderSales / (1 + (18 / 100));
            setNonGstValue(nongst.toFixed(2));
            let shareVal = (share/100)*nongst;
            setShareValue(shareVal.toFixed(2));
        }
        setIsLoading(false);
    },[postData, share])

    useEffect(()=>{
        getOrderData({start: 0, end: 0});
    },[getOrderData]);

    const resetList = () =>{
        if(isLoading){
            return;
        }
        setSelectedDate(null);
        getOrderData({start: 0, end: 0});
    }

    const submitSearch = (e) =>{
        e.preventDefault();
        if(isLoading){
            return;
        }
        let start = 0;
        let end = 0;
        if(selectedDate && selectedDate.length === 2){
            start = selectedDate[0].getTime()
            end = selectedDate[1].setHours(23, 59, 59, 999)
        }
        let body = {
            start,
            end
        }
        getOrderData(body);
    }

    return (
        <Stack padding={2} sx={{background: '#f1f5f9', height: '100%'}}>
            <Box component='form' p={2} m={2} sx={{background: '#fff',borderRadius: 3, boxShadow: '5px 3px 5px #00000005'}} onReset={resetList} onSubmit={submitSearch}>
                <Stack flexDirection='row' gap={2}>
                    <Box width={450}>
                        <DateRangePicker format="MMM dd, yyyy" character=" - " placeholder="Select Date Range" shouldDisableDate={afterToday()} value={selectedDate} onChange={setSelectedDate} />
                    </Box>
                    <IconButton type='submit'>
                        <SearchTwoTone fontSize='large' color='primary' />
                    </IconButton>
                    <IconButton type='reset'>
                        <Close fontSize='large' color='primary' />
                    </IconButton>
                </Stack>
            </Box>
            {isLoading && <Stack direction='row' gap={1} sx={{alignItems: 'center', justifyContent: 'center'}}>
                <ClipLoader size={15} color='#000' />
                <Typography variant='body'>Loading Data</Typography>
            </Stack>}
            {orderStat && <Grid container>
                <Grid item xs={12} lg={4}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Order Value</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {orderStat.totalOrderValue}</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Order Sales</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {orderStat.totalOrderSales}</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Order Sales (excl GST)</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {nonGstValue}</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Share</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{currency} {shareValue} ({share}%)</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Orders</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{orderStat.totalOrders}</Typography>
                    </Stack>
                </Grid>
                {type === "society" && <Grid item xs={12} lg={4}>
                    <Stack m={1.5} sx={{borderRadius: 5, background: '#fff'}} p={2.5} gap={1}>
                        <Typography variant='h6' fontWeight={'bold'} color={'#d9166f'}>Total Customers</Typography>
                        <Typography variant='h4' fontWeight={'bold'}>{orderStat.customers}</Typography>
                    </Stack>
                </Grid>}
            </Grid>}
        </Stack>
    )
}

const mapStateToProps = state => ({
    type: state.configs.type,
    share: state.configs.share
});
export default connect(mapStateToProps)(Dashboard)