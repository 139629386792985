import { createSlice } from "@reduxjs/toolkit";

const configSlice = createSlice({
    name: 'configSlice',
    initialState: {
        loggedIn: null,
        type: 'hotel',
        partnerName: '',
        share: 0
    },
    reducers: { 
        updateLogin(state, action){
            state.loggedIn = action.payload
        },
        updatePartnerName(state, action){
            state.partnerName = action.payload
        },
        updateType(state, action){
            state.type = action.payload
        },
        updateShare(state, action){
            state.share = action.payload
        }
    }
})

export const {updatePartnerName, updateLogin, updateType, updateShare} = configSlice.actions;
export default configSlice.reducer;