import { Stack, Typography } from '@mui/material'
import React from 'react'

function Empty() {
    return (
        <Stack p={4} alignSelf={'center'}>
            <Typography variant='h6'>404 Not Found</Typography>
        </Stack>
    )
}

export default Empty